<template>

    <button onclick="window.print()" class="btn btn-primary mt-4 mb-4 d-print-none"><i class="fas fa-print me-2"></i> Imprimir</button>

<div id="mprint"  class="p-3 bg-white" >

    <div class="row">
        <div class="col-md-6 col-print-6">
            <span class="h5">{{sede.nombreempresasede}}</span> <br>
            {{sede.slogan}}
 
        </div>
        <div class="col-md-6 col-print-6 text-end">
            <div class="p-2 card border-secondary">            
                <span class="h5 titulosg">FICHA DE MATRÍCULA   N°  {{fichamatricula.vigencia}}-{{ ('000' + fichamatricula.numeromatricula).slice (-4)}} </span>
            </div>
        </div>
    </div>
 
    <div class="row mt-2">
        <div class="col-md-12">

                <strong>Información Académica</strong>

                <div class="row">
                    <div class="col-md-6 col-print-6">
                        <div class="border border-secondary p-2 rounded "> 
                            Sede:  {{sede.nombreempresasede}}
                        </div>                        
                    </div>
                    <div class="col-md-3 col-print-3">
                        <div class="border border-secondary p-2 rounded"> 
                            Grado:  {{grados.nombregradogrupo}}
                        </div>                        
                    </div>
                    <div class="col-md-3 col-print-3">
                        <div class="border border-secondary p-2 rounded"> 
                            Jornada:  {{jornada.jornada}}
                        </div>                        
                    </div>

                </div>

  
                <div class="row mt-2">

                    <div class="col-md-6 col-print-6">
                        <div class="p-2 pl-3 rounded" style="background-color:rgb(228, 228, 228);" >
                            Fecha de Matrícula: {{ formatoFecha(fichamatricula.created_at) }}
                        </div>
                    </div>

                    <div class="col-md-6 col-print-6">
                        <div class="p-2 pl-3 rounded" style="background-color: rgb(228, 228, 228);" > 
                            Estado:
                            <span  v-if="fichamatricula.estadomatricula==0"> Cancelada </span>
                            <span  v-if="fichamatricula.estadomatricula==1"> Matrícula Activa </span>
                            <span  v-if="fichamatricula.estadomatricula==2"> Aprobado </span>
                            <span  v-if="fichamatricula.estadomatricula==3"> Promovido(a) </span>
                            <span  v-if="fichamatricula.estadomatricula==4"> Repitente </span>
                            <span  v-if="fichamatricula.estadomatricula==5"> Perdida de Año </span>
                        </div>                        
                    </div>

                </div>
 

        </div>
    </div>


    <div class="row mt-2 mb-2">
        <div class="col-md-12">

            <div class="row">
                <div class="col-md-9 col-print-9">
                    <div class="card border-secondary p-2">
                        
                        Nombre del Estudiante: <br>
                        <span class="h4">
                            {{ estudiante.nombres}}  {{ estudiante.apellidos}}  
                        </span>
                    

                        <table class="table table-sm mb-0">
                            <tbody>
                                <tr>
                                    <td> <span class="mr-3">Identificación:</span>   </td>
                                    <td>  {{ estudiante.identificacion}} de  {{ ciudadexpedicion.nombreciudad}} </td>
                                    <td> <span class="mr-3">Tipo de Identificación:</span>   </td>
                                    <td>  {{ tipodocumento[estudiante.tipoidentificacion] }}    </td>
                                </tr> 
                                <tr>
                                    <td> Fecha de Nacimiento:  </td>
                                    <td> {{ estudiante.fechanacimiento}}  </td>
                                    <td>Lugar de Nacimiento:  </td>
                                    <td> {{ciudadnacimiento.nombreciudad}}</td>
                                </tr>
                                <tr>
                                    <td> Sexo:  </td>                                   
                                    <td>   
                                        <span v-if="estudiante.genero=='H'">Hombre</span> 
                                        <span v-if="estudiante.genero=='M'">Mujer</span> 
                                    </td>                                   
                                    <td> Tipo de Sangre:   </td>
                                    <td> {{estudiante.tiposangre}}  </td>
                                </tr>
                                <tr>
                                    <td>Dirección:    </td>
                                    <td>  {{ estudiante.direccion}} </td>
                                    <td>Barrio:   </td>
                                    <td>  {{ciudadnacimiento.barrio}} </td>
                                </tr>
                                <tr>
                                    <td>Ciudad:  </td>
                                    <td>  {{ ciudadresidencia.nombreciudad}} </td>
                                    <td>Estrato: </td>
                                    <td>  {{ estudiante.estrato}}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono:  </td>
                                    <td> {{ estudiante.telefono}} </td>
                                    <td>Celular:  </td>
                                    <td>  {{ estudiante.celular}}</td>
                                </tr>
                                <tr>
                                    <td>Creencias:  </td>
                                    <td> {{ estudiante.creencias}}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                
                            </tbody>
                        </table>
        
                    </div>
                </div>
                <div class="col-md-3 col-print-3">
                    <div class="border-dark border p-3" style="height: 200px;">
                        Foto
                    </div>
                </div>
            </div>            

        </div>
    </div>
 
                    
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="card p-2 border-secondary">

                <div class="row">
                    <div class="col-md-6">
                       <strong> Institución de Procedencia: </strong> {{fichamatricula.nombreprocedencia}} / {{sectorinstitucionprocedencia.nombreciudad}} 
                    </div>
                    <div class="col-md-6">
                        <strong>Sector: </strong> 
                        <span v-if="fichamatricula.procedencia == 1">Oficial</span>  
                        <span v-if="fichamatricula.procedencia == 2">No Oficial</span>  
                    </div>
                </div>
       
                

            </div>
        </div>
    </div>
             

    <div class="row mt-2">
        <div class="col-md-12"> 
            <div class="p-1">
                <strong>Información Familiar</strong>
            </div>

                <div  class="card border-secondary mb-2 p-2" v-for="item in parientes" :key="item.idpersonas">


                <div class="text-capitalize ">
                   <span class="h6 titulosg me-2">
                    {{item.nombres}} {{item.apellidos}}
                   </span>
                   /
                     <span class="titulosg h6 ms-2">   {{parentesco[item.parentesco]}}</span>
                </div>

                <table class="table table-sm  mb-0">
                    <tbody>
                        <!-- <tr>
                            <td style="width: 20%;" class="text-capitalize"> Nombre Completo: </td>
                            <td style="width: 35%;"><strong>{{item.nombres}} {{item.apellidos}}</strong>   </td>
                            <td style="width: 10%;"> Parentesco:  </td>
                            <td style="width: 35%;"> <strong>{{parentesco[item.parentesco]}}</strong> </td>
                        </tr> -->
                        <tr>
                            <td style="width: 20%;"> Dirección:   </td>
                            <td style="width: 30%;"> {{item.direccion}} </td>
                            <td style="width: 20%;"> Estado Civil: </td>
                            <td style="width: 30%;"> {{estadocivil[item.estadocivil]}} </td>
                        </tr>
                        <tr>
                            <td>  Teléfono / Celular:  </td>
                            <td> {{item.telefono }}   -  {{item.celular}}   </td>
                            <td> Email:  </td>
                            <td> {{item.email }}  </td>
                        </tr>
                        <tr>
                            <td> Empresa:   </td>
                            <td>   {{item.empresa }}  </td>
                            <td> Cargo:    </td>
                            <td>   {{item.cargo }}  </td>
                        </tr>
                        <tr>
                            <td> Ocupación/Actividad Económica:   </td>
                            <td>    {{item.actividadeconomica }}  </td>
                            <td> Profesión:   </td>
                            <td> {{item.profesion }}  </td>
                        </tr>
            
                    </tbody>
                </table>
                </div>

        </div>
    </div>



    <div class="row" style="margin-top: 30px;">
        <div class="col-md-6 col-print-6">
           <div class="border-top border-secondary" >
            Firma del Padre (o Acudiente) <br>
            C.C.
           </div>
        </div>
        <div class="col-md-6 col-print-6">
            <div class="border-top border-secondary"  >
            Firma de la Madre (o Acudiente)  <br>          
            C.C.
            </div>
        </div>
    </div>

    <div class="row border-top mt-2">
        <div class="col-md-12 p-2">
            Dirección {{sede.direccionempresasede}} - Teléfono: {{sede.telefonodependencia}}  - Email: {{sede.emailempresasede}}  - Web: {{sede.url}}
        </div>
    </div>



</div>
</template>

<script>
    import axios from 'axios'
    import { ref, onMounted } from 'vue'
    import moment from 'moment'

    export default {
        props: ['id'],
        setup(props) {

            const fichamatricula = ref({})
            const grados = ref({})
            const jornada = ref({})
            const estudiante = ref({})
            const sede = ref({})
            const ciudadresidencia = ref({})
            const ciudadexpedicion = ref({})
            const ciudadnacimiento = ref({})
            const sectorinstitucionprocedencia = ref({})
            const parientes = ref([])
            const estadocivil = ref(['Soltero/a', 'Casado/a', 'En Relación ( más de 1 Año de noviazgo)', 'Comprometido/a', 'Unión libre o unión de hecho', 'Separado/a', 'Divorciado/a', 'Viudo/a.'])
            const parentesco = ref(['','Padre','Madre', 'Tío(a)', 'Abuelo(a)', 'Hermano(a)' , 'Primo(a)'])


            const tipodocumento = ref(['-', 'Cédula de Ciudadanía', 'Cédula de Extrangería', 'Pasaporte', 'Permiso Especial de Trabajo'])

            const consultarmatricula = () => {

                axios.get('api/matriculaprint/'+props.id).then(response => {
                    fichamatricula.value = response.data
                    grados.value = response.data.grados
                    sede.value = response.data.sede
                    jornada.value = response.data.jornada
                    estudiante.value = response.data.estudiante
                    ciudadresidencia.value = response.data.ciudadresidencia
                    ciudadexpedicion.value = response.data.ciudadexpedicion
                    ciudadnacimiento.value = response.data.ciudadnacimiento
                    sectorinstitucionprocedencia.value = response.data.ciudadnacimiento
                    parientes.value = response.data.parientes




                }).catch(error => {
                    console.log(error)
                })


              

            }

            const formatoFecha = (date) => {
                return moment(date).format('MMM D [de] YYYY, h:mm:ss a');
         }

            onMounted(() => {

                consultarmatricula();

            })

            return { estadocivil, tipodocumento, fichamatricula, formatoFecha, grados, jornada, estudiante,  sede, ciudadresidencia, ciudadexpedicion, ciudadnacimiento, sectorinstitucionprocedencia, parientes, parentesco }

        }

    }
</script>


<style scoped>
 #mprint{
    font-size: 12px;
}
@page 
    {
        size:  auto;   /* auto es el valor inicial */
        margin: 10mm;  /* afecta el margen en la configuración de impresión */
    } 
</style>