<template>
    <div class="row mb-3">
        <div class="col-md-12">

            <div class="mb-3">
                <span class="h4 titulosg">Lista de Estudiantes</span><br>
                <span class="text-secondary  d-print-none"> Seleccione los filtros y presione el boton  <i class="fas fa-search"></i> buscar </span>
            </div>
        </div>

        <div class="col-md-9">
            <div class=" mb-2 text-end d-print-none">
                <button class="btn btn-primary " onclick="window.print()"><i class="fas fa-print"></i> Imprimir</button> 
            </div>
            <div class="card card-body mb-3">
            <table class="table table-sm table-borderless mb-0">
                <tbody>
                    <tr>
                        <td>Institución:</td>
                        <td>
                            <div class="d-flex align-content-center flex-wrap"> 
                                <div v-for="(item, index) in sedeseleccionada" :key="index" class="pt-1 ps-3 pe-3 pb-1 me-1 border bg-light mb-1" style="font-size: 12px;">
                                    <strong>  {{ sedes.find( element => element.idempresasede == item).nombreempresasede }} </strong>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Grado:</td>
                        <td>
                            <div class="d-flex align-content-center flex-wrap"> 
                                <div v-for="(item, index) in gradoseleccionado" :key="index" class="pt-1 ps-3 pe-3 pb-1 me-1 border bg-light mb-1" style="font-size: 12px;">
                                <strong>  {{ grados.find( element => element.idacagradosgrupos == item).nombregradogrupo }} </strong>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Jornada:</td>
                        <td>
                            <div class="d-flex align-content-center flex-wrap"> 
                                <div v-for="(item, index) in jornadaseleccionada" :key="index" class="pt-1 ps-3 pe-3 pb-1 me-1 border bg-light mb-1" style="font-size: 12px;">
                                <strong>  {{ jornadas.find( element => element.idacajornada == item).jornada }} </strong>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Vigencia:</td>
                        <td>
                            <div class="d-flex align-content-center flex-wrap"> 
                                <div v-for="(item, index) in vigenciaseleccionada" :key="index" class="pt-1 ps-3 pe-3 pb-1 me-1 border bg-light mb-1" style="font-size: 12px;">
                                <strong> {{item}} </strong>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Estudiantes:</td>
                        <td>
                            <span class="p-2 bg-light" v-if="estudiantes.length>0">
                                <strong> {{estudiantes.length }}</strong>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Fecha:</td>
                        <td class="text-capitalize">{{fecha}}</td>
                    </tr>
                </tbody>
            </table> 
        </div>

          <table class="bg-white table table-sm table-striped table-bordered border-secondary">            
              <thead>
                  <tr>
                      <td style="width: 25px;" class="text-center"><strong>N</strong></td>
                      <td><strong>Nombre</strong></td>
                      <td><strong>Jornada</strong></td>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(item, index) in estudiantes" :key="item.idpersonas">
                      <td class="text-center"> {{index+1}} </td>
                      <td> {{item.estudiante.nombres}} {{item.estudiante.apellidos}} </td>
                      <td>{{item.jornada.jornada}}</td>
                  </tr>
              </tbody>
          </table>
        </div>

        <div class="col-md-3 d-print-none">

            <form method="POST">

             <div class="titulosg">
                 <i class="fas fa-filter"></i> Filtros de Busqueda:
             </div>
             <div class="p-2">
                <strong> Institución / Sede:</strong> <span class="ms-2 badge bg-secondary">{{sedeseleccionada.length}}</span> <br>
             </div>
                     <div class="card bg-light mb-3">
                         <div class="list-group list-group-flush" id="sedes">
                             <label class="list-group-item list-group-item-action" v-for="item in sedes" :key="item.idempresasede">
                                 <input class="form-check-input me-1" type="checkbox" name="sedes" v-model="sedeseleccionada" :value="item.idempresasede" :id="'sede'+item.idempresasede">
                                 {{item.nombreempresasede}}
                             </label>
                         </div>
                     </div>

                     <div class="p-2">
                         <strong>Grados:</strong> <span class="ms-2 badge bg-secondary">{{gradoseleccionado.length}}</span>
                     </div>
                     <div class="card bg-light mb-3">
                         <div class="list-group  list-group-flush" id="grados">
                             <label class="list-group-item list-group-item-action" v-for="item in grados" :key="item.idempresasede">
                                 <input class="form-check-input me-1" type="checkbox" name="grados" v-model="gradoseleccionado" :value="item.idacagradosgrupos" :id="'grados'+item.idacagradosgrupos">
                                 {{item.nombregradogrupo}}
                             </label>
                         </div>
                     </div>

                     <div class="p-2">
                         <strong>Jornada: </strong><span class="ms-2 badge bg-secondary">{{jornadaseleccionada.length}}</span>
                     </div>
                     <div class="card bg-light mb-3">
                         <div class=" list-group list-group-flush" id="jornada">
                             <label class="list-group-item list-group-item-action" v-for="item in jornadas" :key="item.idacajornada">
                                 <input class="form-check-input me-1" type="checkbox" name="grados" v-model="jornadaseleccionada" :value="item.idacajornada" :id="'grados'+item.idacajornada">
                                 {{item.jornada}}
                             </label>
                         </div>
                     </div>
                     
                     <div class="p-2">
                         <strong> Vigencia:</strong> <span class="ms-2 badge bg-secondary">{{jornadaseleccionada.length}}</span>
                     </div> 
                     <div class="card bg-light mb-3">
                         <div class=" list-group list-group-flush" id="jornada">
                             <label class="list-group-item list-group-item-action" v-for="(item,index) in vigencia" :key="index">
                                 <input class="form-check-input me-1" type="checkbox" name="vigencia" v-model="vigenciaseleccionada" :checked="item.vigencia==anioactual" :value="item.vigencia" :id="'vigencia'+item.vigencia">
                                 {{item.vigencia}}
                                </label>
                            </div>
                        </div>
                        
                        <div class="mt-2 ">
                            <button class="btn btn-primary" type="button" @click="ConsultarEstudiantes()"><i class="fas fa-search"></i> Buscar</button>
                        </div> 
            </form>
        </div>

 
    </div>
</template>

<script>

import {ref,onMounted, watch} from 'vue'
import axios from 'axios'
import moment from 'moment'

export default {
    setup(props) {
     
    const sedes = ref({})
    const grados = ref({})
    const jornadas = ref([])
    const vigencia = ref([])

    const sedeseleccionada = ref([])
    const gradoseleccionado = ref([])
    const jornadaseleccionada = ref([])
    const vigenciaseleccionada = ref([])

    const estudiantes = ref({})
    const anioactual = ref(new Date().getFullYear())
    const fecha = ref()

    const listasedespermitidas = () => {
        axios.get('/api/sedesautorizadas').then(response => {
            sedes.value = response.data
        }).catch(error=>{ console.log(error) })
    }

    const gradosxsede = () => {
        axios.post('/api/gradosxsede', { data: sedeseleccionada.value } ).then( response => {
            grados.value = response.data;
        })
    }

    const ConsultarJornadas = () => {
        axios.get('/api/jornadas').then( response => {
            jornadas.value = response.data;
        })
    }
  
    const ConsultarVigencias = () => {
        axios.get('/api/vigenciasmatriculadas').then( response => {
            vigencia.value = response.data;
        })
    }

    const ConsultarEstudiantes = () => {
        axios.post('/api/listaestudiantes',{ sedes: sedeseleccionada.value, grados: gradoseleccionado.value , jornadas: jornadaseleccionada.value, vigencias: vigenciaseleccionada.value }).then( response => {
            estudiantes.value = response.data
        })
    }

    watch(sedeseleccionada, () => { 
        if(sedeseleccionada.value !== null){
            gradosxsede()
        }
    })

    onMounted(() => {
        listasedespermitidas()
        ConsultarJornadas()
        ConsultarVigencias()

        fecha.value = moment().format('MMMM D [de] YYYY, h:mm:ss a')

    })

     return {
            sedes,            
            grados,
            jornadas,
            vigencia,
            estudiantes,
            anioactual,
            fecha,  

            sedeseleccionada,
            gradoseleccionado,
            jornadaseleccionada,
            vigenciaseleccionada,

            //Funciones
            ConsultarEstudiantes,
            
            }

    }
}
</script>

<style>

</style>
