<template>
<div>

        <div class="text-center mt-5 " v-if="cargando">
            <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <br>
            Cargando ...
        </div>

        <div v-if="!cargando">
 
            <div class="h4 titulosg mb-3">Estudiantes </div>
            <div class="row mb-3">
                <div class="col-md-12">
                    
                    <div class="position-relative">
                        <form v-on:submit.prevent="buscarEstudiante()" method="POST">
                            <div class="input-group">
                                <input autocomplete="off" placeholder="Buscar" type="text" class="form-control" v-model="criteriobusqueda" id="criteriobusqueda" required aria-describedby="button-addon2">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit"> <i class="fas fa-search"></i> Buscar </button>
                                    <button class="btn btn-outline-secondary" type="button" @click="listarestudiantes(); criteriobusqueda=''" title="Actualizar"> <i class="fas fa-sync-alt"></i> </button>
                                </div>
                            </div>
                        </form>
                        
                        <div class="position-absolute top-100 start-0 bg-white card mt-1 shadow p-3 w-100"  v-if="criteriobusqueda.length>2" style="z-index: 1;">
                            <div class="mb-3">
                                <button class="btn btn-outline-secondary float-end" @click="criteriobusqueda=''"><i class="far fa-trash-alt"></i></button>
                                Resultado encontrados <span class="badge bg-primary"> {{resultadobusqueda.length}} </span> para: <span class="badge bg-primary text-white"> {{criteriobusqueda}} </span>
                            </div>
                            <ul class="list-group list-group-flush">
                                <li v-for="(item, index) in resultadobusqueda" :key="index" class="list-group-item list-group-item-action" aria-disabled="true"  @click="verPerfil(item.idpersonas)">
                                    <div class="d-flex d-flex-row position-relative">
                                        <div>
                                            <img  style="max-height: 40px; border: solid 5px rgb(243, 243, 243);  border-radius: 10px;" class="float-start border-3 me-3"  :src="archivospublicos+'/personas/'+item.foto" alt="">
                                        </div>
                                        <div>
                                            <strong>{{item.nombres}} {{item.apellidos}}</strong><br>
                                             {{item.identificacion}} 
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div class="alert alert-warning text-center mt-3" v-if="resultadobusqueda.length == 0">
                                No hay resultados para <strong> {{criteriobusqueda}}</strong> , por favor intente nuevamente 
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="d-flex d-flex-row mb-3">
                                <div class="me-3"> Total:  <span class="badge bg-primary">{{personas.length}}</span> </div>
                                <div class="me-3"> Por Página: </div>
                                <div class="me-3">
                                    <select class="form-select form-select-sm" name="porpagina" id="porpagina" v-model="porpagina" style="width: 50px;" >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                    </select>
                                </div>   
                            </div>
                        </div>
                        <div class="col-md-6 d-flex flex-row">
                         </div>
                    </div>
               
                    <div class="card rounded-3">
                        <table class="table table-hover mb-0">                                                        
                            <thead>
                                <tr>
                                    <th class="header"  style="width: 10px;"> </th>
                                    <th class="header">Foto</th>
                                    <th class="header" style="width: 12%;">Identificación</th>
                                    <th class="header">Nombre</th>
                                    <th class="header">Sede</th>
                                    <th class="header">Grado</th>
                                    <th class="header">Jornada</th>
                                    <th class="header">Estado Actual</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in personas.slice(inicio,fin)" :key="item.idpersonas" @click="verPerfil(item.estudiante.idpersonas)">
                                    <td class="text-center">{{((index+1)+((paginaactual-1))*porpagina)}}</td>
                                    <td style="width: 40px;"> <img  style="max-height: 30px; border: solid 5px rgb(243, 243, 243);  border-radius: 10px;" class="float-start border-3 me-3"  :src="archivospublicos+'/personas/'+item.estudiante.foto" alt=""></td>
                                    <td class=" text-secondary">  {{item.estudiante.identificacion}} </td>
                                    <td class="text-capitalize">  {{item.estudiante.nombres}} {{item.estudiante.apellidos}}</td>
                                    <td class="text-capitalize">  {{item.sede.nombreempresasede}} </td>
                                    <td class="text-capitalize">  {{item.grados.nombregradogrupo}} </td>
                                    <td class="text-capitalize">  {{item.jornada.jornada}} </td>
                                    <td class=""> 
                                        <span class="badge bg-secondary" v-if="item.estadomatricula==0"> Cancelada </span>     
                                        <span class="badge bg-success" v-if="item.estadomatricula==1"> Matrícula Activa </span>    
                                        <span class="badge bg-secondary" v-if="item.estadomatricula==2"> Aprobado </span>    
                                        <span class="badge bg-secondary" v-if="item.estadomatricula==3"> Promovido </span>  
                                        <span class="badge bg-secondary" v-if="item.estadomatricula==4"> Repitente </span>    
                                        <span class="badge bg-danger" v-if="item.estadomatricula==5"> Reprobado </span>  
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class=" mt-3 mb-4">
                        <div class="float-end">
                            <span class="float-right">Página {{paginaactual}} de {{totalpagina}}</span>
                        </div>

                        <div class="d-flex d-flex-row">
                            <div class="me-3">
                                <button type="button" class="btn btn-primary btn-sm" @click="paginaactual=1">Primera Página</button>
                                <button type="button" class="btn btn-primary btn-sm ms-2" v-if="paginaactual>1" @click="paginaactual--"> <i class="fas fa-angle-left"></i> </button>
                            </div>
                            <div class="me-3">
                                Página Actual
                            </div>
                            <div class="me-2">
                                <select id="page" class="form-select form-select-sm" v-model="paginaactual">
                                    <option  v-for="(n, index) in totalpagina" :key="index" :value="n"> {{n}} </option>
                                </select>
                            </div>
                            <div class="me-3">
                                 de {{totalpagina}}
                            </div>
                            <div class="me-3">

                                <button type="button" class="btn btn-primary btn-sm me-2" v-if="paginaactual < totalpagina" @click="paginaactual++"><i class="fas fa-angle-right"></i></button>
                                <button type="button" class="btn btn-primary btn-sm" @click="paginaactual=totalpagina">Última Página</button>
                            </div>
                        </div>
                    </div>
 
                </div>
            </div>

        </div>
        <!-- Fin Cargando -->
</div>
</template>

<script>

import axios from 'axios'
import { ref, computed, watch, onMounted } from 'vue'
import router from '../../router'


export default{

    setup(props) {
        
        const cargando = ref(true)
        const personas = ref([])

        const criteriobusqueda = ref('')
        const resultadobusqueda = ref([])

        const porpagina = ref(10)
        const paginaactual = ref(1)
        const totalpagina = ref()
        const inicio = ref(0)
        const fin = ref(10)

        const sedesautorizadas = ref([])

        watch(criteriobusqueda, () =>{
            buscarEstudiante()
        })

        watch([paginaactual,porpagina], () => {


            if(paginaactual.value > 1){
                inicio.value = (parseFloat(paginaactual.value)-1)*parseFloat(porpagina.value)
            }else{
                inicio.value = 1;
            }
                fin.value = parseFloat(inicio.value)+parseFloat(porpagina.value)

            totalpagina.value = Math.round(personas.value.length/porpagina.value);
            
            if(inicio.value > personas.value.length){
                paginaactual.value = 1
            }

        })

        const listarestudiantes = () => {

                let config = {
                    params: {  'page': paginaactual.value }, 
                    onDownloadProgress: function (progressEvent) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    } 
                }
                axios.get('/api/estudiantes', config).then(response => {
                   personas.value = response.data;
                   totalpagina.value = Math.round(response.data.length/porpagina.value);
                   cargando.value = false;

                });
        }

        const buscarEstudiante = () => {

            if(criteriobusqueda.value.length > 3){
                axios.post('/api/busquedaestudiante',{ data: criteriobusqueda.value }).then(response => { 
                    resultadobusqueda.value = response.data;  
                })
            }
            
        }

        const verPerfil = (id) => {
            router.push({  name: 'datosestudiantes', params: { id: id } });
            // router.push({  name: 'infoestudiante', params: { id: id } });
        }

        onMounted(() => {
                listarestudiantes()
        })


        return { 
                 cargando,
                 personas,
                 resultadobusqueda,
                 criteriobusqueda,

                 porpagina,
                 paginaactual,
                 totalpagina,
                 inicio,
                 fin,

                 sedesautorizadas ,
                 listarestudiantes,
                 buscarEstudiante,
                 verPerfil,
        }

    }

}
   
</script>
<style>

</style>